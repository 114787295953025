<!-- @Author: Yu_Bo -->
<template>
	<div class='works_video list'>
		<div class="vue_title">
			视频管理
		</div>
		<div class="main">
			<search-params ref="SearchParams" is_name="video" :is_name_mobile="true" :is_order_source="true"
				:order_source_list="order_source_list" :is_identity="true" :user_identity_list="user_identity_list"
				:is_create_time="true" @search="search"></search-params>
			<table-list :list="list" :loading="loading" @getList="getList" @previewBtn="previewBtn"
				@shelfBtn="shelfBtn"></table-list>
			<div class="page_box pr20">
				<paging-page :total='total' align='right' @sizeChange='sizeChangeBtn'
					@currentChange='currentChangeBtn'></paging-page>
			</div>
		</div>
		<!-- 预览 -->
		<video-preview ref="video_preview" title="查看" :info="info"></video-preview>
		<!-- 下架 -->
		<point-out ref='dialogTip' dialogTitle="温馨提示" type="sound" @close='closeListBtn' dialogWidth='400px'
			:iconShow='true' iconColor='#FF7432' borderRadius='10px' :cancelShow='true' :affirmShow='true'>
			<div class="zc_dialog_tip_content">
				<div class="text">下架作品后该作品为失败，您确定要下架该视频作品吗？</div>
			</div>
		</point-out>
	</div>
</template>

<script>
import PagingPage from '@/components/paging_page/paging_page.vue'//分页
import SearchParams from '../components/search/index.vue' // 筛选项
import TableList from '../components/tableList/videoList.vue' // 列表
import VideoPreview from '@/components/video_preview/index.vue'//预览
import PointOut from '@/components/point_out/point_out.vue'//温馨提示
export default {
	components: {
		PagingPage,
		SearchParams,
		TableList,
		VideoPreview,
		PointOut,
	},
	props: {},
	data() {
		return {
			params: {},
			user_identity_list: [], // 用户身份类型
			order_source_list: [], // 作品来源
			list: [],
			page: 1,
			limit: 10,
			total: 0,
			loading: false,
			id: null,
			info: {},
		}
	},
	computed: {},
	watch: {},
	created() {
		this.getParams()
		this.getList()
	},
	mounted() { },
	methods: {
		getParams() {
			this.$worksApi.getDrawParams().then(res => {
				if (res.code == 1000) {
					this.order_source_list = res.result.order_source
					this.user_identity_list = res.result.identity
				}
			})
		},
		search(params) {
			this.params.name = params.name
			this.params.keyword = params.name_mobile
			this.params.identity = params.identity
			this.params.order_source = params.order_source
			this.params.started = params.date ? `${params.date[0]}` : ''
			this.params.ended = params.date ? `${params.date[1]}` : ''

			this.page = 1
			this.list = []
			this.total = 0
			this.getList()
		},
		resetFrom() {
			this.$refs.SearchParams.resetFrom()
			this.page = 1
			this.list = []
			this.total = 0
			this.params = {}
			this.getList()
		},
		sizeChangeBtn(val) {
			this.limit = val
			this.page = 1
			this.getList();
		},
		currentChangeBtn(val) {
			this.page = val;
			this.getList();
		},
		getList() {
			const data = {
				page: this.page,
				limit: this.limit,
				...this.params,
			}
			this.loading = true
			this.$worksApi.getVideoList(data).then(res => {
				this.loading = false
				if (res.code == 1000) {
					this.list = res.result.data
					this.total = res.result.total
				} else {
					this.$errMsg(res.message)
				}
			})
		},
		previewBtn(info) {
			this.info = info
			this.$refs.video_preview.openDialogBtn()
		},
		// 下架
		shelfBtn(id) {
			this.id = id
			this.$refs.dialogTip.openDialogBtn()
		},
		closeListBtn() {
			const data = {
				id: this.id,
				shelf_status: 2, // 2==下架
			}
			this.$worksApi.videoShelf(data).then(res => {
				if (res.code == 1000) {
					this.$succMsg(res.message)
					this.getList()
				} else {
					this.$errMsg(res.massage)
				}
			})
		},
	},
}
</script>

<style lang='scss' scoped>
@import "../index.scss";

.works_video {
	height: calc(100vh - 270px);

}
</style>
